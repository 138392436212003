import { IonRouterOutlet } from "@ionic/react";
import React, { useEffect } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router";
import PaymentScreen from "../components/chat/PaymentScreen";
import WebView from "../components/webview";
import TrackerComponent from "../containers/HomePage/StepAndSleepChart/index";
import homePage from "../containers/HomePage/homePage";
import AssesmentComponent from "../containers/assesment/assessment";
import CareTeamComponent from "../containers/careTeam/index";
import DocumentViewContainer from "../containers/documents/DocumentViewContainer";
import DocumentContainer from "../containers/documents/newIndex";
import hamburger from "../containers/hamburger";
import HealthGoal from "../containers/healthGoals";
import HealthGoalScreenComponent from "../containers/healthGoals/healthGoalsScreen";
import HealthPlanComponent from "../containers/healthPlan/index";
import PaytmLogin from "../containers/login/PaytmLogin";
import MedicineOrderComponent from "../containers/medicineOrder/index";
import MeditationComponent from "../containers/meditation";
import PremiumPage from "../containers/premium/newIndex";
import PatientProfile from "../containers/profile/index";
import Registration from "../containers/registration/newIndex";
import Testimonials from "../containers/registration/testimonials";
import SubmittedReporting from "../containers/reporting/components/submittedReporting";
import ReportingContainer from "../containers/reporting/newIndex";
import VitalContainer from "../containers/vitals/newIndex";
import VitalsChart from "../containers/vitalsChart";

import IframeComponent from "../components/paytmOffers/IframeComponent";
import DentalCare from "../containers/HomePage/components/DentalCare";
import Diabetes from "../containers/diabetesDigit";
import Digit from "../containers/digit";
import CovidCare from "../containers/digit/CovidCare";
import HeartHealth from "../containers/digit/HeartHealth";
import WeightManagement from "../containers/digit/WeightManagement";
import WomenHealth from "../containers/digit/WomenHealth";
import SyncedVitals from "../containers/connectDevices/SyncedVitals";
import Settings from "../containers/settings/Settings";
import BookingHistory from "../containers/orderHistory";

import FamilyPlan from "../containers/familyInfo/newIndex";

import { CircularProgress } from "@mui/material";
import AddressBook from "../containers/address/newIndex";
import MedicineOrderComplete from "../containers/medicineOrderComplete";
import NavigoBooking from "../containers/navigo/newIndex";
import SessionExpired from "../containers/session-expired";
import SessionCheck from "../containers/sessionCheck/Sessioncheck";
import zylaWallet from "../containers/zylaWallet/newIndex";

import { Plugins } from "@capacitor/core";
import { useState } from "react";
import { useSelector } from "react-redux";

import CustomerSupport from "../containers/hamburger/coustmerSupportPage";
import LoginPage from "../containers/login/newLogin";
import OfflinePage from "../containers/offline";
import { Policy } from "../containers/policy/policyPage";
import Privacy from "../containers/privacy";
import TabPage from "../containers/tab";
import Terms from "../containers/terms";
import Faqs from "../containers/zylaWallet/rewardPages/faqs";
import RewardInfo from "../containers/zylaWallet/rewardPages/rewardInfo";
import RewardPointLedger from "../containers/zylaWallet/rewardPages/rewardPointLedger";
import RewardPointPage from "../containers/zylaWallet/rewardPages/rewardPointPage";

import Feed from "../containers/HomePage/Feed";
import Consultation from "../containers/mliNavigo/consultation";
import Diagnostics from "../containers/mliNavigo/DiagnosticPage";
import FullBodyHealthCheckup from "../containers/mliNavigo/BodyHealthCheckup";
import HealthAssesment from "../containers/mliNavigo/HealthAssesment";
import HealthRiskAssesment from "../containers/mliNavigo/HealthRiskAssesment";
import HealthRiskAssesment2 from "../containers/mliNavigo/HealthRiskAssesment2";
import { BookingSuccessfully } from "../containers/mliNavigo/component/BookingSuccessfully";
import { BookingSuccessfullyCard } from "../containers/medicineOrder/BookingSuccessfullyCard";

import { MYPlan2 } from "../containers/HomePage/components/MYPlan2";
import { EligibilityCheck } from "../containers/login/components/EligibilityCheck";
import TermsCondition from "../containers/login/components/termsCondition";
import GenerateVoucher from "../containers/login/components/generateVoucher";
import { MyPlan3 } from "../containers/HomePage/components/MyPlan3";
import RewardPoint from "../containers/rewardPoint";
import RegistrationPage from "../containers/registration/newIndex";
import StepathonStepCard from "../containers/stepathon/components/stepathonStepCrad";
import StepathonPage from "../containers/stepathon/stepathonPage";
import StepathonAboutPage from "../containers/stepathon/stepathonAboutPage";
import StepathonPage2 from "../containers/stepathon/stepathonPage2";
import { Network } from "@capacitor/network";
import CidrReward from "../containers/zylaWallet/rewardPages/cidrReward/cidrReward";
import AutoLogin from "../containers/autologin";

export default function RouteComponent() {
  const [loaded, setLoaded] = useState(false);
  const isLoggedIn = useSelector((state) => state.login.loggedIn);
  const isFirstLogin = useSelector((state) => state.login.isFirstLogin);

  const isRegistered = useSelector((state) => state.login.registered);
  const history = useHistory();

  useEffect(() => {
    Network.getStatus()
      .then((status) => {
        if (status.connected === false) {
          history.push("/offline");
          setLoaded(true);
        } else {
          setLoaded(true);
        }
      })
      .catch((err) => {
        setLoaded(true);
        console.log("cannot detetct status");
      });
  }, []);

  useEffect(() => {
    if (!isLoggedIn && window.isReload) {
      setLoaded(true);
    }
  }, [isLoggedIn]);

  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          isLoggedIn ? (
            <Component {...props} />
          ) : window.location.pathname === "/session-expired" ? (
            <Redirect to="/session-expired" />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  };

  return loaded ? (
    <IonRouterOutlet animated="false" className="responsive-class-ion-router">
      <Route exact path="/session-expired" component={SessionExpired}></Route>
      <Route exact path="/terms-condition" component={TermsCondition}></Route>

      <Route
        exact
        path="/"
        render={() => {
          return <Redirect to="/login" />;
        }}
      />
      <PrivateRoute exact path="/assessment" component={AssesmentComponent} />

      <PrivateRoute path="/tab/feed" component={Feed} />
      <PrivateRoute
        exact
        path="/health-goals-screen"
        component={HealthGoalScreenComponent}
      />
      <PrivateRoute exact path="/home" component={homePage} />
      <Route
        exact
        path="/login"
        render={() => {
          return isLoggedIn ? (
            isFirstLogin ? (
              <Redirect to="/terms-condition" push={true} />
            ) : (
              <Redirect to="/testimonials" />
            )
          ) : (
            <LoginPage />
          );
        }}
      ></Route>
      <PrivateRoute
        exact
        path="/profile"
        component={PatientProfile}
      ></PrivateRoute>
      <PrivateRoute path="/hamburger" component={hamburger} />

      <PrivateRoute
        exact
        path="/careTeam"
        component={CareTeamComponent}
      ></PrivateRoute>

      <PrivateRoute path="/tab" component={TabPage}></PrivateRoute>
      <PrivateRoute
        exact
        path="/reporting"
        component={ReportingContainer}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/submittedReporting"
        component={SubmittedReporting}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/vitals"
        component={VitalContainer}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/dentalCare"
        component={DentalCare}
      ></PrivateRoute>
      <PrivateRoute path="/diabetes" component={Diabetes}></PrivateRoute>
      <PrivateRoute path="/hearthealth" component={HeartHealth}></PrivateRoute>
      <PrivateRoute
        path="/weight-management"
        component={WeightManagement}
      ></PrivateRoute>
      <PrivateRoute path="/covid19-care" component={CovidCare}></PrivateRoute>
      <PrivateRoute path="/womenhealth" component={WomenHealth}></PrivateRoute>
      <Route exact path="/digit" component={Digit}></Route>
      <PrivateRoute
        exact
        path="/documents"
        component={DocumentContainer}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/document-viewer"
        component={WebView}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/documents/:type"
        component={DocumentViewContainer}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/trackingChart"
        component={TrackerComponent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/meditation"
        component={MeditationComponent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/vitalsChart"
        component={VitalsChart}
      ></PrivateRoute>
      <Route
        exact
        path="/doctor-consult/paytm-offer"
        render={(props) => (
          <IframeComponent
            {...props}
            url="https://zyla.in/doctor-consult/paytm-offer"
          />
        )}
      ></Route>
      <Route
        exact
        path="/webinars/6139cb79ee50930023811d55"
        render={(props) => (
          <IframeComponent
            {...props}
            url="https://zyla.in/webinars/6139cb79ee50930023811d55"
          />
        )}
      ></Route>
      <Route
        exact
        path="/webinars/612dc450fceb630023dd8441"
        render={(props) => (
          <IframeComponent
            {...props}
            url="https://zyla.in/webinars/612dc450fceb630023dd8441"
          />
        )}
      ></Route>
      <Route
        exact
        path="/doctor-consult/paytm-offer"
        render={(props) => (
          <IframeComponent
            {...props}
            url="https://zyla.in/doctor-consult/paytm-offer"
          />
        )}
      ></Route>
      <Route
        exact
        path="/webinars/6139cb79ee50930023811d55"
        render={(props) => (
          <IframeComponent
            {...props}
            url="https://zyla.in/webinars/6139cb79ee50930023811d55"
          />
        )}
      ></Route>

      <PrivateRoute
        exact
        path="/payment"
        component={PaymentScreen}
      ></PrivateRoute>
      {/* <PrivateRoute
          exact
          path="/hamburger"
          component={HamburgerPage}
        ></PrivateRoute> */}
      <PrivateRoute
        exact
        path="/medicine"
        component={MedicineOrderComponent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/medicine/bookingCard"
        component={BookingSuccessfullyCard}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/premium"
        component={PremiumPage}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/risk-Assesment"
        component={HealthRiskAssesment}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/risk-Assesment2"
        component={HealthRiskAssesment2}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/body-health-checkup"
        component={FullBodyHealthCheckup}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/health-assesment"
        component={HealthAssesment}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/syncedVitals"
        component={SyncedVitals}
      ></PrivateRoute>
      <PrivateRoute exact path="/settings" component={Settings}></PrivateRoute>
      <PrivateRoute
        exact
        path="/stepathon"
        component={StepathonPage}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/stepathon2"
        component={StepathonPage2}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/stepathonabout"
        component={StepathonAboutPage}
      ></PrivateRoute>
      <PrivateRoute exact path="/family" component={FamilyPlan}></PrivateRoute>
      <PrivateRoute
        exact
        path="/address"
        component={AddressBook}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/order-history"
        component={BookingHistory}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/sessioncheck"
        component={SessionCheck}
      ></PrivateRoute>
      {/* <PrivateRoute
        exact
        path="/session-expired"
        component={SessionExpired}
      ></PrivateRoute> */}
      <PrivateRoute
        exact
        path="/ordercomplete"
        component={MedicineOrderComplete}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/navigo"
        component={NavigoBooking}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/mliNavigo"
        component={Consultation}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/mliNavigo/diagnostics"
        component={Diagnostics}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/mliNavigo/medicines"
        component={MedicineOrderComponent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/mliNavigo/bookingCard"
        component={BookingSuccessfully}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/zylawallet"
        component={zylaWallet}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/offline"
        component={OfflinePage}
      ></PrivateRoute>

      <PrivateRoute exact path="/health-plan" component={HealthPlanComponent} />
      <PrivateRoute exact path="/health-goals" component={HealthGoal} />
      <PrivateRoute exact path="/my-plan" component={MYPlan2} />
      <PrivateRoute exact path="/policy" component={Policy} />
      <PrivateRoute exact path="/sewa-myplan" component={MyPlan3} />
      <PrivateRoute exact path="/cidr-myplan" component={MyPlan3} />

      <PrivateRoute
        exact
        path="/coustmer-support"
        component={CustomerSupport}
      />
      <PrivateRoute exact path="/reward-point-info" component={RewardPoint} />

      <Route
        exact
        path="/eligibility-check"
        component={EligibilityCheck}
      ></Route>

      <Route exact path="/privacy" component={Privacy}></Route>
      <Route exact path="/terms" component={Terms}></Route>
      <Route exact path="/testimonials" component={Testimonials}></Route>
      <Route exact path="/rewardInfo" component={RewardInfo}></Route>
      <Route exact path="/rewardPointPage" component={RewardPointPage}></Route>

      <Route exact path="/faqs" component={Faqs}></Route>
      <Route exact path="/cidrReward/:_id" component={CidrReward}></Route>

      <Route
        exact
        path="/rewardPointLedger"
        component={RewardPointLedger}
      ></Route>
      <Route exact path="/terms-condition" component={TermsCondition}></Route>
      <Route exact path="/generate-voucher" component={GenerateVoucher} />
      <Route exact path="/registervoucher" component={RegistrationPage} />
      <Route exact path="/autologin" component={AutoLogin}></Route>
    </IonRouterOutlet>
  ) : (
    <React.Fragment>
      <CircularProgress className="ion-router-circular-progress"></CircularProgress>
    </React.Fragment>
  );
}
