import ApiService from "./ApiService";
import AuthenticationService from "./AuthenticationService";
import APIConfig from "./APIConfig";
import axiosClient from "../app/axiosConfig";

class UserService {
  constructor() {
    this.apiService = new ApiService();
    this.authenticationService = new AuthenticationService();
  }

  getOTP(phoneNo, countryCode, dob) {
    const url = `${APIConfig.user.getMliOtp}`;
    return axiosClient.post(url, {
      phoneNo: phoneNo,
      dob: dob,
    });
  }
  verifyOtpNew(params) {
    const url = APIConfig.user.verifyOTP;
    return axiosClient.post(url, params);
  }
  verifyOTP(params, onSuccess, onError) {
    const url = APIConfig.user.verifyOTP;

    this.apiService.post(
      url,
      params,
      (response) => {
        response.user.otpSent = true;
        this.authenticationService.setToken(response.token);
        this.authenticationService.setUserProfile(response.user);
        this.authenticationService.activateSession(response.user.authToken);
        onSuccess(response);
      },
      onError
    );
  }

  registerUser(params, user) {
    const url = APIConfig.user.register;

    let registerRequest = {
      docCode: params.docCode,
      documentId: params.documentId,
      user: {
        patientProfile: {
          createdAt: 0,
          email: params.email,
          firstName: params.firstName,
          gender: params.gender,
          lastName: params.lastName,
          updatedAt: 0,
        },
        phoneNo: user.phoneNo,
        countrycode: user.countrycode,
      },
    };
    return axiosClient.post(url, registerRequest);
  }
  searchDoctorCode(code, onSuccess, onError) {
    const url = APIConfig.user.verifyDocCode.format(code);
    return axiosClient.get(url);
  }

  uploadProfilePic(file, fileName, pid = null, user) {
    let uploadRequestForm = new FormData();
    uploadRequestForm.append("file", file);
    uploadRequestForm.append("fileMetadata", {
      name: fileName,
      size: file.length,
      type: "form-data",
      fileName: fileName,
    });
    let patientId = user?.patientProfile.id;
    if (pid) {
      patientId = pid;
    }
    const url = APIConfig.patient.uploadProfilePic.format(patientId);

    return axiosClient.putForm(url, uploadRequestForm);
  }
  checkCallBackStatus() {
    let user = this.authenticationService.getUserProfile();
    return user.patientProfile.status === 12;
  }
  requestCallBack(id) {
    const url = APIConfig.patient.requestCall.format(id);
    return axiosClient.put(url, null);
  }
  firstCall(code, onSuccess, onError) {
    const url = APIConfig.user.paytm;

    this.apiService.post(
      url,
      code,
      (response) => {
        this.authenticationService.setUserProfile(response.user);
        this.authenticationService.activateSession(response.user.authToken);

        onSuccess(response);
      },
      onError
    );
  }
  getLoginImages() {
    return axiosClient.get(APIConfig.freemium.loginImgs, {
      headers: {
        client: undefined,
        origin: "*",
      },
    });

    // this.apiService.get(
    //   APIConfig.freemium.loginImgs,
    //   (res) => {
    //     onSuccess(res);
    //   },
    //   (err) => {
    //     console.log("Something went wrong", err);
    //     onError(err);
    //   },
    //   {
    //     origin: "*",
    //   }
    // );
  }
  getHealthPlans = (id, onSuccess, onError) => {
    let url = APIConfig.premium.plans.format(id);
    this.apiService.get(
      url,
      (res) => {
        onSuccess(res);
      },
      (err) => {
        onError(err);
      }
    );
  };
  getAllHealthPlans = (id, onSuccess, onError) => {
    let url = APIConfig.premium.allPlans;
    this.apiService.get(
      url,
      (res) => {
        onSuccess(res);
      },
      (err) => {
        onError(err);
      }
    );
  };
  getLatestprofile = (id) => {
    let url = APIConfig.latestprofile.getProfile.format(id);
    return axiosClient.get(url);
  };
  getLatestSettings = (id) => {
    const url = APIConfig.user.setting.format(id);
    return axiosClient.get(url);
  };
  getPrimaryTherapy = (id, onSuccess, onError) => {
    const url = APIConfig.user.fetchTherapy;
    this.apiService.get(
      url,
      (res) => {
        onSuccess(res);
      },
      (err) => {
        onError(err);
      }
    );
  };
  getClientInfo = (params, token, onSuccess, onError) => {
    const url = APIConfig.user.clientConfig;

    return axiosClient.post(url, params);
    // return axios.post(url, params, {
    //   headers: {
    //     client: "bridge_web",
    //     phone_no: params.phoneNo,
    //     auth_token: token,
    //   },
    // });
    // let user = this.authenticationService.getUserProfile();
    // let myHeader = {
    //   client: "bridge_web",
    //   phone_no: params.phoneNo,
    //   auth_token: user ? user.authToken : "",
    // };

    // return fetch(url, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     ...myHeader,
    //   },
    //   body: JSON.stringify({ ...params }),
    // })
    //   .then((response) => {
    //     if (response.status >= 400 && response.status < 600) {
    //       if (response.status === 404) {
    //         onSuccess(null);
    //       } else {
    //         throw new Error("Bad response from server");
    //       }
    //     } else {
    //       response
    //         .json()
    //         .then((data) => {
    //           onSuccess(data);
    //           return;
    //         })
    //         .catch((err) => {
    //           onError(err);
    //         });
    //     }
    //   })
    //   .catch((err) => {
    //     onError(err);
    //   });
  };
  postOrgEmail = (params) => {
    const url = APIConfig.user.orgEmailVerify;
    return axiosClient.post(url, params);
  };
  verifyOrgEmail = (params) => {
    const url = APIConfig.user.orgOtpVerify;
    return axiosClient.post(url, params);
  };
  postSource = (params) => {
    let url = APIConfig.user.sourceonTentacle;
    return axiosClient.post(url, params);
  };

  patchUserSettings = (id, params) => {
    let url = APIConfig.user.updateSettings.format(id);
    return axiosClient.patch(url, params);
  };
  loadPrevMessages = (url, onResponse, onError) => {
    this.apiService.get(
      url,
      (res) => {
        onResponse(res);
      },
      (err) => {
        onError(err);
      }
    );
  };

  getFoodList = (id, type) => {
    let url = APIConfig.healthPlan.getFoodList.format(id, type);
    return axiosClient.get(url);
  };
  createSSORequest = (patientId, memberPatientId, onResponse, onError) => {
    let url = APIConfig.user.createRequest;
    let body = {
      patientId: patientId,
      memberPatientId: memberPatientId,
    };
    this.apiService.post(url, body, onResponse, onError);
  };
  getClientCodeInfo = (cc, onResponse, onError) => {
    let url = APIConfig.user.clientConfigUsingClientCode.format(cc);
    return this.apiService.get(url, onResponse, onError);
  };

  //submit user feedback
}
export default new UserService();
