import { IonPage, IonContent } from "@ionic/react";
import backImage from "../../../assets/images/back.png";
import arrowdownImage from "../../../assets/images/arrow-ios-down.png";
// import plusIcon from "../../../assets/images/plus.png";
import arrowRightFilled from "../../../assets/icons/arrrow-right-filled.svg";
import arrowRight from "../../../assets/icons/arrrow-right.svg";
import arrowLeft from "../../../assets/icons/arrrow-left.svg";
import arrowLeftFilled from "../../../assets/icons/arrrow-left-filled.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRewardLedgerData } from "./rewardSlice";
import moment from "moment";
import { PlusIcon, PlusSmallIcon } from "@heroicons/react/24/solid";
import BackButton from "../../../components/common/BackButton";

export default function RewardPointLedger() {
  const dispatch = useDispatch();
  const selectedPolicy = useSelector((state) => state.reward.selectedPolicy);

  const defaultPolicy = useSelector((state) => state.reward.defaultPolicy);

  const [showModel, setshowModel] = useState(false);
  const [showLastMonth, setshowLastMonth] = useState(true);
  const [currentMonthSelected, setCurrentMonthSelected] =
    useState("last_month");
  const [currentPage, setCurrentPage] = useState(1);
  const patientProfile = useSelector(
    (state) => state.login?.user?.patientProfile
  );

  const ledgerData = useSelector((state) => state.reward.rewardLedgerData);

  const ledgerPages = useSelector(
    (state) => state.reward.rewardLedgerPageCount
  );

  function handleCloseModel(e) {
    setshowModel(false);
  }

  const handlePrevPageClick = () => {
    if (currentPage != 1) setCurrentPage((currentPage) => currentPage - 1);
  };

  const handleNextPageClick = () => {
    if (currentPage < ledgerPages) setCurrentPage((currentPage) => currentPage + 1);
  };

  const handleFilterClick = (e) => {
    setshowModel(false);
    setCurrentMonthSelected(e.target.value);
    setCurrentPage(1);
  };

  const fetchLedgerData = () => {
    let body = {
      id: patientProfile?.id,
      policyId: selectedPolicy,
      page: currentPage,
      month: currentMonthSelected,
    };
    dispatch(getRewardLedgerData(body));
  };

  useEffect(() => {
    fetchLedgerData();
  }, [currentPage, currentMonthSelected]);

  return (
    <IonPage>
      <IonContent>
        <div className="container ">
          <div className="p-4 flex space-x-3 items-center text-center">
            {/* <Link to="/rewardPointPage" className="no-underline">
              <img src={backImage} alt="image" className="w-6 h-6" />
            </Link> */}
            <BackButton />
            <div className=" font-bold text-base leading-7 text-lightblack">
              Reward points history
            </div>
          </div>
          <div className="flex justify-between p-4 items-start text-center">
            <div className="font-bold text-xs leading-5  text-lightblack cursor-pointer">
              Filter
            </div>
            <button
              className="  flex items-center text-center px-2 text-xs text-lightblack  bg-white rounded-lg border-mliblue-class"
              onClick={() => setshowModel(true)}
            >
              {currentMonthSelected == "last_month" ? (
                <span className="font-semibold text-xs leading-7">
                  Last month
                </span>
              ) : currentMonthSelected == "last_3_month" ? (
                <span className="font-semibold text-xs leading-7">
                  Last 3 months
                </span>
              ) : (
                <span className="font-semibold text-xs leading-7">
                  Last 6 months
                </span>
              )}
              <img
                src={arrowdownImage}
                alt=""
                className={`h-6 w-6  ${
                  showModel ? "rotate-180 delay-70  " : null
                } `}
              />
            </button>
          </div>

          {ledgerData ? (
            ledgerData?.map((e) => {
              return (
                <>
                  <div className="flex justify-between p-4">
                    <div className="flex gap-x-2 items-start">
                      <PlusSmallIcon
                        width={16}
                        height={16}
                        color="#1BC47D"
                        className=""
                      />

                      <div className="">
                        <p className="m-0 font-semibold text-sm text-lightblack leading-4">
                          {e.rewardPoint} points
                        </p>
                        <p className="m-0 text-[10px]">
                          {e.reewardType == "Step"
                            ? "Daily step count"
                            : e.reewardType == "hra"
                            ? "Health risk assessment"
                            : e.reewardType == "chc"
                            ? "Complimentary health checkup "
                            : e.reewardType == "mhc"
                            ? "Monthly health challenge"
                            : e.reewardType == "bs"
                            ? "Blood sugar within normal parameters"
                            : e.reewardType == "chl"
                            ? "Cholesterol within normal parameters"
                            : e.reewardType == "bp"
                            ? "Blood pressure within normal parameters"
                            : e.reewardType == "bmi"
                            ? "BMI within normal parameters"
                            : ""}
                        </p>
                      </div>
                    </div>
                    {showLastMonth && (
                      <div className="text-xs font-medium leading-5">
                        {moment(e.recordedAt).format("DD-MM-YYYY")}
                      </div>
                    )}
                  </div>
                </>
              );
            })
          ) : (
            <div className="text-center text-md text-[#171a21]">
              No records found
            </div>
          )}
          <div className="fixed bottom-2 left-[50%] transform translate-x-[-50%]">
            <div className="flex">
              <img
                src={currentPage == 1 ? arrowLeft : arrowLeftFilled}
                onClick={handlePrevPageClick}
              />
              <p className="mr-3 ml-3">Page {currentPage}</p>
              <img
                src={currentPage < ledgerPages ? arrowRightFilled : arrowRight}
                onClick={handleNextPageClick}
              />
            </div>
          </div>
        </div>

        {showModel && (
          <>
            <div
              className="fixed inset-0 bg-lightblack bg-opacity-50 transition-opacity cursor-pointer"
              id="modal-title"
              onClick={() => setshowModel(false)}
            ></div>
            <div className="fixed bottom-0 flex flex-col items-start left-1/2 transform -translate-x-1/2 w-full bg-white border border-gray-300 shadow-md p-4 rounded-t-md z-50 text-lightblack">
              <div className="font-semibold text-xs leading-7">
                Choose filter range
              </div>

              <button
                type="button"
                value="last_month"
                className="font-noraml text-xs leading-7 p-0 bg-white text-lightblack"
                onClick={(e) => handleFilterClick(e)}
              >
                Last month
              </button>
              <button
                type="button"
                value="last_3_month"
                className="font-noraml text-xs leading-7 p-0 bg-white text-lightblack"
                onClick={(e) => handleFilterClick(e)}
              >
                Last 3 month
              </button>
              <button
                type="button"
                value="last_6_month"
                className="font-noraml text-xs leading-7 p-0 bg-white text-lightblack"
                onClick={(e) => handleFilterClick(e)}
              >
                Last 6 month
              </button>
            </div>
          </>
        )}
      </IonContent>
    </IonPage>
  );
}
